<template>
	<div>
		<div class="form-group">
			<label for="id">{{ $t('monte.conditions') }} *</label>
			<e-select
				:disabled="disabled"
				v-model="type_monte"
				id="id"
				track-by="id"
				label="name"
				:placeholder="$t('monte.rechercher_conditions_monte')"
				:selectedLabel="$t('global.selected_label')"
				:options="type_montes"
				:allow-empty="false"
				:show-labels="false"
				:loading="loadingTypeMonte"
				:sortable="false"
				:class="{ 'is-invalid': errors && errors.indexOf('type_monte') > -1 }"
			></e-select>
			<div class="alert alert-warning" v-if="display_message_condition && message_conditions != ''">
				{{ message_conditions }}
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import Config from '@/mixins/Config.js'

	export default {
		name: 'TypeMonte',
		mixins: [Navigation, ContractMixin],
		props: {
			contractconfig_id:{
				type: Number,
				default: () => ( 0 )
			},
			avenant_typemonte:{
				type: Number,
				default: () => ( 0 )
			},
			value:{
				type: Object,
				default: () => ( null )
			},
			errors: {
				type: Array,
				default: () => ( [] )
			},
			disabled:{
				type: Boolean,
				default: () => ( false )
			},
		},
		data () {
			return {
                loadingTypeMonte: false,
                type_montes: [],
				display_message_condition: false,
				message_conditions: '',
			}
		},
		computed: {
			type_monte: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.contractPrivacyPolicy()
                    .then(res => {
                        this.message_conditions  = res.message_conditions
                    })

				this.loadTypeMonte()
			},

			async loadTypeMonte() {
				this.type_montes = []
				let type_montes_ids = []
				this.type_monte = null

				if(this.contractconfig_id != 0) {
					let user_country = this.getConfig("user_country")

					let type_monte = await this.getContractConfigTypeMonte(this.contractconfig_id)

					if(type_monte.length == 0) {
						this.display_message_condition = true
					}
					else {
						this.display_message_condition = false
						for (let i = 0; i < type_monte.length; i++) {
							const typemonte_label = this.getTrad(type_monte[i].type_monte.contracttypemonte_label)
							// const articles = type_monte[i].linked_articles.map(a => `${a.articles_label} (${this.priceFormat(a.articles_ht, 'EUR', true)})`)

							let tab_articles = []
							for (let j = 0; j < type_monte[i].conditions.length; j++) {
								const element = type_monte[i].conditions[j];
								for (let k = 0; k < element.conditions_articles.length; k++) {
									const config_articles = element.conditions_articles[k];
									if(config_articles.articles){

										tab_articles.push({
											articles_label: config_articles.articles.articles_label,
											articles_ttc: config_articles.contractconfigconditionsarticles_ttc / 100
										})
									}
								}
							}

							const option = {
								id: type_monte[i].contractconfigtypemonte_id,
								contractconfig: type_monte[i].contractconfigtypemonte_contractconfig,
								name: this.getTrad(`${typemonte_label} - ${type_monte[i].contractconfigtypemonte_label}`),
								articles: tab_articles,
								type_monte: type_monte[i].type_monte.contracttypemonte_type,
								paillettes: type_monte[i].paillettes,
								pays: type_monte[i].pays
							}
							
							if((type_monte[i].pays.length > 0 && type_monte[i].pays.includes(user_country)) || type_monte[i].pays.length == 0) {
								this.type_montes.push(option)
								type_montes_ids.push(type_monte[i].contractconfigtypemonte_id)

								if(this.avenant_typemonte == type_monte[i].contractconfigtypemonte_id) {
									this.type_monte = option
								}
							}
						}
					}

                    // Lorsqu'un type de monte est en valide = 0 parce que le modèle a été supprimé, on le propose quand même en option
                    if(this.avenant_typemonte != null && this.avenant_typemonte) {
	                    const type_monte_old = await this.getContractConfigTypeMonteFromId(this.avenant_typemonte)

	                    if(type_monte_old.contractconfigtypemonte_contractconfig == this.contractconfig_id && type_monte_old != undefined && type_montes_ids.indexOf(type_monte_old.contractconfigtypemonte_id) == -1) {

		                    const option = {
		                        id: type_monte_old.contractconfigtypemonte_id,
		                        name: this.getTrad(type_monte_old.contract_type_monte.contracttypemonte_label) + " - " + type_monte_old.contractconfigtypemonte_label,
		                    } 
		                    this.type_montes.push(option)
		                    this.type_monte = option
	                    }
                    }
				}

				if(!this.type_monte && this.type_montes.length == 1) {
					this.type_monte = this.type_montes[0]
				}
			}
		},
		watch: {
			contractconfig_id() {
				this.loadTypeMonte()
			}
		}
	}

</script>